import Head from 'next/head'
import { SEOMetatags } from 'service'

interface SEOProps {
	seo: SEOMetatags
	pageTitle?: string
}

export const SEO = ({ seo, pageTitle }: SEOProps) => {
	const { site_name, domain, url, locale, metatags } = seo ?? {}
	const {
		title,
		description,
		isIndexable,
		og_title,
		og_description,
		og_image,
		twitter_title,
		twitter_description,
		twitter_image,
	} = metatags ?? {}

	const trimMetaDescription = (description: string) => {
		let trimmedDescription = description

		if (description.length > 160) {
			trimmedDescription = description.substring(0, 156)
			trimmedDescription.concat('...')
		}

		return trimmedDescription
	}

	const _description = description && trimMetaDescription(description)
	const _og_description = og_description && trimMetaDescription(og_description)
	const _twitter_description =
		twitter_description && trimMetaDescription(twitter_description)

	return (
		<Head>
			{isIndexable === false && <meta name="robots" content="noindex" />}
			<title key="page-title">{pageTitle || title}</title>
			<meta
				key="viewport"
				name="viewport"
				content="width=device-width, initial-scale=1, minimum-scale=1"
			/>
			<meta key="title" name="title" content={pageTitle || title} />
			<meta key="description" name="description" content={_description} />
			<meta key="og:site_name" property="og:site_name" content={site_name} />
			<meta key="og:type" property="og:type" content="website" />
			<meta
				key="og:title"
				property="og:title"
				content={og_title || pageTitle || title}
			/>
			<meta
				key="og:description"
				property="og:description"
				content={_og_description || _description}
			/>
			<meta key="og:url" property="og:url" content={url} />
			{locale && <meta key="og:locale" property="og:locale" content={locale} />}
			{og_image && (
				<>
					<meta key="og:image" property="og:image" content={og_image} />
					<meta key="og:image:width" property="og:image:width" content="1200" />
					<meta
						key="og:image:height"
						property="og:image:height"
						content="630"
					/>
				</>
			)}
			{og_image && og_title && (
				<meta
					key="og:image:alt"
					property="og:image:alt"
					content={og_title || pageTitle || title}
				/>
			)}
			<meta
				key="twitter:card"
				name="twitter:card"
				content="summary_large_image"
			/>
			<meta key="twitter:domain" property="twitter:domain" content={domain} />
			<meta key="twitter:url" property="twitter:url" content={url} />
			<meta
				key="twitter:title"
				name="twitter:title"
				content={twitter_title || pageTitle || title}
			/>
			<meta
				key="twitter:description"
				name="twitter:description"
				content={_twitter_description || _description}
			/>
			{twitter_image && (
				<meta
					key="twitter:image"
					name="twitter:image"
					content={twitter_image}
				/>
			)}
		</Head>
	)
}
